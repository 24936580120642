/*
 * File: Pricing.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Wednesday, 10th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */




const Pricing = () => {

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
            <div className="row">
                <h1 className="text-left">Pricing</h1>
                <div className="col mb-3">
                    <div className="card text-white bg-dark" style={{ maxWidth: "25rem", minHeight: "450px" }}>
                        <div className="card-header">SOLOPRENEUR</div>
                        <div className="card-body">
                            <h4 className="card-title" style={{ fontSize: "3rem", minHeight: "50px" }}>€399</h4>
                            <div className="card-text">
                                <p className="explained">Turn Your Ideas into AI-Driven Solutions Rapidly</p>
                                <hr />
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Circuits
                                    </div>
                                    <div className="col text-end">
                                        5
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Circuits
                                    </div>
                                    <div className="col text-end">
                                        1
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Users
                                    </div>
                                    <div className="col text-end">
                                        1000
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Data Hosting
                                    </div>
                                    <div className="col text-end">
                                        100,000
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Continuous Learning
                                    </div>
                                    <div className="col text-end">
                                        6 months
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor API Call
                                    </div>
                                    <div className="col text-end">
                                        100,000
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor Platform
                                    </div>
                                    <div className="col text-end">
                                        10,000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col  mb-3">
                    <div className="card text-white bg-dark" style={{ maxWidth: "25rem", minHeight: "450px" }}>
                        <div className="card-header">SME</div>
                        <div className="card-body">
                            <h4 className="card-title" style={{ fontSize: "3rem", minHeight: "50px" }}>€3999</h4>
                            <div className="card-text">
                                <p className="explained">Empower Your Established Business with In-House AI Expertise</p>
                                <hr />
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Circuits
                                    </div>
                                    <div className="col text-end">
                                        100
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Circuits
                                    </div>
                                    <div className="col text-end">
                                        20
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Users
                                    </div>
                                    <div className="col text-end">
                                        100,000
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Data Hosting
                                    </div>
                                    <div className="col text-end">
                                        10,000,000
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Continuous Learning
                                    </div>
                                    <div className="col text-end">
                                        Indefinite
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor API Call
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor Platform
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col mb-3">
                    <div className="card text-white bg-dark " style={{ maxWidth: "25rem", minHeight: "450px" }}>
                        <div className="card-header">ENTERPRISE</div>
                        <div className="card-body">
                            <h3 className="card-title">Contact us</h3>
                            <h5>sales@exoneuron.org</h5>
                            <div className="card-text align-items-end">
                                <p className="explained">
                                    Customized AI Solutions for Large-Scale, Intelligent Agent Training
                                </p>
                                <hr />
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Circuits
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Circuits
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Active Users
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Data Hosting
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Continuous Learning
                                    </div>
                                    <div className="col text-end">
                                        Indefinite
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor API Call
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col text-start">
                                        Tutor Platform
                                    </div>
                                    <div className="col text-end">
                                        Unlimited
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}

export default Pricing