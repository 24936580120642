/*
 * File: Sidebar.jsx
 * File Created: Sunday, 31st March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Saturday, 27th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircuitList from './CircuitList';


function Sidebar({ user }) {
    const navigate = useNavigate();

    const [isCollapsed, setIsCollapsed] = useState(true);
    const defaultPhoto = "https://img.icons8.com/ios/50/FFFFFF/user--v1.png";

    const handleMouseEnter = () => {
        setIsCollapsed(false);
    }

    const handleMouseLeave = () => {
        setIsCollapsed(true);
    }

    const onClickProfile = () => {
        navigate('/profile');
    }

    const onClickBrain = () => {
        navigate('/Profile');
    }

    const onClickCreateNewCircuit = () => {
        navigate('/create');
    }


    return user !== null ? (
        <div className={`bg-primary ${isCollapsed ? 'collapsed' : ''}`}
            style={{ height: '100vh', width: isCollapsed ? '100px' : '320px', margin: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="text-white d-flex align-items-center justify-content-center flex-column">
                <button id="profileBtn" className="btn btn-primary text-start w-100"
                    onClick={onClickProfile}>
                    <img width="50" height="50" src={user?.photoURL || defaultPhoto} alt="Profile"
                        style={{ borderRadius: '10px', marginRight: '24px', marginLeft: '8px' }} />
                    {!isCollapsed && user ? <b style={{ fontSize: 'larger' }}>{user.displayName}</b> : ""}
                </button>
                <button id="BrainBtn" className="btn btn-primary text-start w-100"
                    onClick={onClickBrain}>
                    <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/artificial-intelligence.png"
                        style={{ marginRight: '24px', marginLeft: '8px' }}
                        alt="" />
                    {!isCollapsed && <b style={{ fontSize: 'larger' }}>Brain</b>}
                </button>
                <CircuitList isCollapsed={isCollapsed} />
                <button id="sidebarCreateNewCircuitBtn" className="btn btn-primary text-start w-100"
                    onClick={onClickCreateNewCircuit}>
                    <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/add.png"
                        style={{ marginRight: '24px', marginLeft: '8px' }}
                        alt="" />
                    {!isCollapsed && <b style={{ fontSize: 'larger' }}>New Circuit</b>}
                </button>
                <button id="sidebarDataPointBtn" className="btn btn-primary text-start w-100">
                    <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/mind-map--v1.png"
                        style={{ marginRight: '24px', marginLeft: '8px' }}
                        alt="" />
                    {!isCollapsed && <b style={{ fontSize: 'larger' }}>Data Points</b>}
                </button>
                <button id="sidebarSettingsBtn" className="btn btn-primary text-start w-100">
                    <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/settings.png"
                        style={{ marginRight: '24px', marginLeft: '8px' }}
                        alt="" />
                    {!isCollapsed && <b style={{ fontSize: 'larger' }}>Settings</b>}
                </button>
            </div>
        </div>
    ) :
        (<></>);
}


export default Sidebar;
