/*
 * File: CircuitList.jsx
 * File Created: Tuesday, 2nd April 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Saturday, 27th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { list_circuits } from "../auth/firebase";

const CircuitList = ({ isCollapsed }) => {
    const [circuits, setCircuits] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCircuits = async () => {
            try {
                const result = await list_circuits({ text: 'test' });
                console.log("Result from httpsCallable: ", result);
                setCircuits(result.data);
            } catch (error) {
                console.error("Error calling httpsCallable: ", error);
            }
        };
        fetchCircuits();
    }, []);

    const handleCircuitClick = (circuitId) => {
        navigate(`/circuits/${circuitId}`);
    }
    const [showCircuits, setShowCircuits] = useState(false);

    const handleButtonClick = () => {
        console.log(circuits);
        setShowCircuits(!showCircuits);
    }

    return (
        <>
            <button id="sidebarCircuitBtn" className="btn btn-primary text-start w-100" onClick={handleButtonClick}>
                <img width="50" height="50" src="https://img.icons8.com/ios/50/FFFFFF/circuit.png"
                    style={{ marginRight: '24px', marginLeft: '8px', padding: '0px'}}
                    alt=""
                />
                {!isCollapsed && <b style={{ fontSize: 'larger' }}>Circuits</b>}
            </button>
            {!isCollapsed && showCircuits && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {Object.keys(circuits).map((key) => (
                        <button className="btn btn-primary text-start w-100"
                            style={{ padding: '0px', marginLeft: '30px', marginBottom: '10px' }}
                            key={key} onClick={() => handleCircuitClick(circuits[key])}>
                            {key}
                        </button>
                    ))}
                </div>
            )}
        </>
    );

}
export default CircuitList;