/*
 * File: About.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Thursday, 28th March 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */



const About = () => {
    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
            <div className="row justify-content-center">
                <div className="col-md-6 text-left">
                    <h1>About us</h1>
                    <br />                    
                    <div>
                        At our core, we are a dynamic team of AI researchers and software engineers, driven by a shared
                        vision to transcend the current boundaries of AI technology.
                        Our expertise recognizes that AI's potential is often constrained within the realm of
                        single-circuit
                        neural networks. In response, we've created an innovative platform that empowers you to
                        conceptualize and design complex artificial neural network architectures.
                        These networks are not just standalone entities; they interact and feed into each other,
                        mimicking
                        the intricate workings of an organic brain. Once you've designed your artificial brain, our
                        technology brings it to life, seamlessly integrating it into your applications or artificial
                        agents.
                        We don't stop there. Our platform is equipped with capabilities for online active learning,
                        ensuring
                        that your AI continuously evolves and adapts while in deployment. This means you can focus
                        entirely
                        on crafting and enhancing the environments of your applications or agents, leaving the cognitive
                        growth and learning of your AI in our capable hands.
                    </div>
                    <br />                    
                    <h3>Our Mission</h3>
                    <br />                    

                    <div>
                        Nature presents us with a fascinating spectrum of brains, each uniquely tuned to its environment
                        -
                        from the simplistic yet effective neural systems of insects to the complex and powerful human
                        brain.
                        This diversity in natural intelligence inspires our mission.
                        We believe that the digital age, rich in varied forms of data, necessitates a similar diversity
                        in
                        artificial intelligence. Our goal is to bridge the existing gap in AI development, moving beyond
                        the
                        conventional focus on language, vision, and audio. We are committed to <b>empowering you</b> to
                        create a range of artificial brains, each sensitively attuned to different data forms, contexts,
                        memorization and generality/specificity.
                        This diversity is not just our goal; it's our response to the ever-evolving landscape of
                        information
                        and interaction in our world, ensuring that every AI we create is as unique and adaptable as the
                        environment it is designed for.
                    </div>
                    <br />                    
                    <h4>Contact us contact@exoneuron.org</h4>
                </div>
            </div>
        </div>
    )
}

export default About