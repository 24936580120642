import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import About from "../pages/About";
import CircuitDetail from "../pages/CircuitDetail";
import Create from "../pages/Create";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Pricing from "../pages/Pricing";
import Profile from "../pages/Profile";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout></Layout>}>
                    <Route index element={<Home></Home>}></Route>
                    <Route path="/home" element={<Home></Home>}></Route>
                    <Route path="/Profile" element={<Profile></Profile>}></Route>
                    <Route path="/pricing" element={<Pricing></Pricing>}></Route>
                    <Route path="/about" element={<About></About>}></Route>
                    <Route path="/login" element={<Login></Login>}></Route>
                    <Route path="/create" element={<Create></Create>}></Route>
                    <Route path="/circuits/:circuitId" element={<CircuitDetail></CircuitDetail>}></Route>
                    <Route path="*" element={<Home></Home>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App