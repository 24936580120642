/*
 * File: Login.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Saturday, 27th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../auth/firebase";


const handleAuthStateChange = (navigate) => {
    return auth.onAuthStateChanged(user => {
        if (user !== null) {
            navigate("/Profile");
        } else {
            navigate("/login");
        }
    });
};

// Function to handle login
const handleLogin = async (navigate, email, password, setNotice) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/Profile");
    } catch {
        setNotice("You entered a wrong username or password.");
    }
}

// Function to handle sign up
const handleSignUp = async (navigate, email, password, setNotice) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate("/Profile");
    } catch {
        setNotice("Could not create account. Please try again.");
    }
}

// Function to handle UI changes for sign up and sign in
const handleUIChange = (isSignUp, setNotice) => {
    const signUpDiv = document.getElementById("signUpDiv");
    const signInDiv = document.getElementById("signInDiv");
    const repeatPasswordDiv = document.getElementById("repeatPassword");
    setNotice("");
    if (isSignUp) {
        signUpDiv.style.display = "block";
        signInDiv.style.display = "none";
        repeatPasswordDiv.style.display = "block";
    } else {
        signUpDiv.style.display = "none";
        signInDiv.style.display = "block";
        repeatPasswordDiv.style.display = "none";
    }
}



const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState("");

    const user = auth.currentUser;

    useEffect(() => {
        if (user !== null) {
            navigate("/Profile");
        }
    }, [user, navigate]);

    useEffect(() => {
        handleAuthStateChange(navigate);
    }, [navigate]);

    const loginWithUsernameAndPassword = async (e) => {
        e.preventDefault();
        handleLogin(navigate, email, password, setNotice);
    }
    
    const toggleSignUp = () => {
        setIsSignUp(!isSignUp);
    }

    const signUpWithEmailAndPassword = async (e) => {
        e.preventDefault();
        if (password !== repeatPassword) {
            setNotice("Passwords do not match.");
            return;
        }
        handleSignUp(navigate, email, password, setNotice);
    }

    useEffect(() => {
        handleUIChange(isSignUp, setNotice);
    }, [isSignUp]);

    useEffect(() => {
        const form = document.getElementById("emailPasswordSignInForm");
        if (form) {
            form.onsubmit = isSignUp ? signUpWithEmailAndPassword : loginWithUsernameAndPassword;
        }
        const repeatPasswordInput = document.getElementById("repeatPasswordInput");
        if (repeatPasswordInput) {
            repeatPasswordInput.value = repeatPassword;
            repeatPasswordInput.onchange = (e) => setRepeatPassword(e.target.value);
        }
    }, [isSignUp, signUpWithEmailAndPassword, loginWithUsernameAndPassword, repeatPassword]);


    // handle google sign in
    const handleSignInWithGoogle = async (navigate, setNotice) => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            navigate("/Profile");
        } catch {
            setNotice("Could not sign in with Google. Please try again.");
        }
    }

    return (
        <div className="container d-flex align-items-center justify-content-center"
            style={{ height: '100vh' }}>
            <div className="col-md-3 align-items-center justify-content-center">
                <div className="row align-items-center justify-content-center">
                    <h1 className="d-flex justify-content-center">Login</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <form id="emailPasswordSignInForm" className="mb-4" onSubmit={isSignUp ? signUpWithEmailAndPassword : loginWithUsernameAndPassword}>
                            <div className="form-group">
                                <input type="email" className="form-control" id="emailInput" placeholder="Email"
                                    style={{}} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" id="passwordInput" placeholder="Password"
                                    style={{}} value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="form-group" id="repeatPassword"
                                style={{ display: "none" }}>
                                <input type="password" className="form-control" id="repeatPasswordInput" placeholder="Repeat Password"
                                    style={{}} value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                            </div>
                            <div id="signInDiv">
                                <button type="submit" className="btn btn-primary align-items-center justify-content-left" id="emailSignInBtn"
                                    style={{ marginTop: "10px", width: "100%" }}>
                                    <div className="d-flex align-items-center">
                                        <img width="36" height="36" src="https://img.icons8.com/ios/50/FFFFFF/enter-2.png"
                                            style={{ marginRight: "24px" }}
                                            alt=""
                                        />
                                        Sign in with Email
                                    </div>
                                </button>
                                <div className="d-flex align-items-center justify-content-center"
                                    style={{ marginTop: "10px" }}>
                                    <p>New user? <a onClick={toggleSignUp} style={{ textDecoration: 'underline' }}> Sign up</a></p>
                                </div>
                            </div>
                            <div id="signUpDiv"
                                style={{ display: "none" }}>
                                <button type="submit" className="btn btn-primary align-items-center justify-content-left" id="emailSignUpBtn"
                                    style={{ marginTop: "20px", width: "100%" }}>
                                    <div className="d-flex align-items-center">
                                        <img width="36" height="36" src="https://img.icons8.com/ios/50/FFFFFF/add-user-male.png"
                                            style={{ marginRight: "24px" }}
                                            alt=""
                                        />
                                        Sign up with Email
                                    </div>
                                </button>
                                <div className="d-flex align-items-center justify-content-center"
                                    style={{ marginTop: "10px" }}>
                                    <p>Already have an account? <a onClick={toggleSignUp} style={{ textDecoration: 'underline' }}> Sign in</a></p>
                                </div>
                                <div id="signUpError" className="text-danger" role="alert"
                                    style={{ display: "none" }}></div>
                            </div>
                            <div id='notice' className="text-danger d-flex align-items-center justify-content-center" role="alert"
                                style={{ display: notice ? "block" : "none" }}>
                                {notice}
                            </div>

                        </form>
                        <hr
                            style={{ width: "100%", }} />
                        <button id="googleSignInBtn" className="btn btn-primary d-flex align-items-center justify-content-left"
                            style={{ marginTop: "20px", width: "100%" }}
                            onClick={() => handleSignInWithGoogle(navigate, setNotice)}
                        >
                            <div className="d-flex align-items-center">
                                <img width="36" height="36" src="https://img.icons8.com/ios/50/FFFFFF/google-logo.png"
                                    style={{ marginRight: "24px" }}
                                    alt=""
                                />
                                Sign in with Google
                            </div>
                        </button>
                        <button id="githubSignInBtn" className="btn btn-primary d-flex align-items-center justify-content-left"
                            style={{ marginTop: "20px", width: "100%" }}>
                            <div className="d-flex align-items-center">
                                <img width="36" height="36" src="https://img.icons8.com/ios/ios/50/FFFFFF/github.png"
                                    style={{ marginRight: "24px" }}
                                    alt=""
                                />
                                Sign in with Github
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login