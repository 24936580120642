/*
 * File: Profile.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Saturday, 27th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import React, { useEffect, useState } from 'react';
import { auth } from "../auth/firebase";

const Profile = () => {
    const defaultPhoto = "https://img.icons8.com/ios/50/FFFFFF/user--v1.png";
    const [user, setUser] = useState(null);

    // if not logged in, redirect to login page
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user !== null) {
                setUser(user);
            }
        });
        return unsubscribe;
    }, []);
    return (
        <div className="container-fluid text-white d-flex align-items-center justify-content-center flex-column"
            style={{ marginTop: 0 }}>
            <h1>Profile</h1>

            <div className="d-flex align-items-center justify-content-center flex-column">
                <img width="200" height="200" src={user?.photoURL || defaultPhoto} alt="Profile"
                    style={{ borderRadius: '10px', marginRight: '24px', marginLeft: '8px' }} />
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
                <h3>{user?.displayName}</h3>
                <h5>{user?.email}</h5>
            </div>

        </div>
    );
}

export default Profile