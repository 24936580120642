import ace from 'ace-builds/src-noconflict/ace';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import React, { useState } from 'react';
import AceEditor from "react-ace";
import { useNavigate } from 'react-router-dom';

import { create_circuit } from '../auth/firebase';

// Set the base path to CDN for all related Ace Editor files
ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
ace.config.setModuleUrl('ace/mode/json_worker', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/worker-json.js');

function Create() {
    const navigate = useNavigate();
    const [circuitType, setCircuitType] = useState("");
    const [circuitName, setCircuitName] = useState("");
    const [recognitionInput, setRecognitionInput] = useState("");
    const [recognitionOutput, setRecognitionOutput] = useState("");
    const [retrievalReference, setRetrievalReference] = useState("");
    const [retrievalQuery, setRetrievalQuery] = useState("");
    const [sourceSeparationSource, setSourceSeparationSource] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const renderForm = () => {
        switch (circuitType) {
            case 'recognition':
                return (
                    <>
                        <br />
                        <label>Input</label>
                        <AceEditor
                            key="recognitionInput"
                            mode="json"
                            theme="monokai"
                            onChange={setRecognitionInput}
                            value={recognitionInput}
                            name="inputEditor"
                            editorProps={{ $blockScrolling: true , $useWorker: false}}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                        />
                        <br />
                        <label>Output</label>
                        <AceEditor
                            key="recognitionOutput"
                            mode="json"
                            theme="monokai"
                            onChange={setRecognitionOutput}
                            value={recognitionOutput}
                            name="outputEditor"
                            editorProps={{ $blockScrolling: true , $useWorker: false}}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                        />
                    </>
                );
            case 'retrieval':
                return (
                    <>
                        <br />
                        <label>Reference</label>
                        <AceEditor
                            key="retrievalReference"
                            mode="json"
                            theme="monokai"
                            onChange={setRetrievalReference}
                            value={retrievalReference}
                            name="referenceEditor"
                            editorProps={{ $blockScrolling: true , $useWorker: false}}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                        />
                        <br />
                        <label>Query</label>
                        <AceEditor
                            key="retrievalQuery"
                            mode="json"
                            theme="monokai"
                            onChange={setRetrievalQuery}
                            value={retrievalQuery}
                            name="queryEditor"
                            editorProps={{ $blockScrolling: true , $useWorker: false}}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                        />
                    </>
                );
            case 'sourceSeparation':
                return (
                    <>
                        <br />
                        <label>Source</label>
                        <AceEditor
                            key="sourceSeparationSource"
                            mode="json"
                            theme="monokai"
                            onChange={setSourceSeparationSource}
                            value={sourceSeparationSource}
                            name="sourceEditor"
                            editorProps={{ $blockScrolling: true , $useWorker: false}}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                            style={{ width: '100%', minHeight: '150px' }}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const createCircuit = () => {
        console.log('Creating circuit');
        if (circuitName === '' || circuitType === '') {
            setErrorMessage('Circuit name and type cannot be empty');
            console.log('Circuit name and type cannot be empty');
            return;
        }
        // handling for each type of circuit creation
        switch (circuitType) {
            case 'recognition':
                if (recognitionInput === '' || recognitionOutput === '') {
                    setErrorMessage('Recognition input and output cannot be empty');
                    console.log('Recognition input and output cannot be empty');
                    return;
                }
                // check if input and output are valid JSON
                try {
                    JSON.parse(recognitionInput);
                    JSON.parse(recognitionOutput);
                } catch (error) {
                    setErrorMessage('Invalid JSON format for input or output');
                    console.log('Invalid JSON format for input or output');
                    return;
                }
                create_circuit({ name: circuitName, 
                                 type: circuitType, 
                                 input: recognitionInput, 
                                 output: recognitionOutput })
                    .then((result) => { // returns the circuit id
                        console.log('Circuit created successfully');
                        navigate('/circuits/' + result.data.brain_id);
                    })
                    .catch((error) => {
                        console.log('Error creating circuit', error);
                        setErrorMessage(error.message);
                    });
                break;
            case 'retrieval':
                if (retrievalReference === '' || retrievalQuery === '') {
                    setErrorMessage('Retrieval reference and query cannot be empty');
                    console.log('Retrieval reference and query cannot be empty');
                    return;
                }
                // check if reference and query are valid JSON
                try {
                    JSON.parse(retrievalReference);
                    JSON.parse(retrievalQuery);
                } catch (error) {
                    setErrorMessage('Invalid JSON format for reference or query');
                    console.log('Invalid JSON format for reference or query');
                    return;
                }

                create_circuit({ name: circuitName, type: circuitType, reference: retrievalReference, query: retrievalQuery })
                    .then((result) => {
                        console.log('Circuit created successfully');
                        navigate('/circuits/' + result.data.brain_id);
                    })
                    .catch((error) => {
                        console.log('Error creating circuit', error);
                        setErrorMessage(error.message);
                    });
                break;
            case 'sourceSeparation':
                if (sourceSeparationSource === '') {
                    setErrorMessage('Source separation source cannot be empty');
                    console.log('Source separation source cannot be empty');
                    return;
                }
                // check if source is valid JSON
                try {
                    JSON.parse(sourceSeparationSource);
                } catch (error) {
                    setErrorMessage('Invalid JSON format for source');
                    console.log('Invalid JSON format for source');
                    return;
                }
                create_circuit({ name: circuitName, type: circuitType, source: sourceSeparationSource })
                    .then((result) => {
                        console.log('Circuit created successfully');
                        navigate('/circuits/' + result.data.brain_id);
                    })
                    .catch((error) => {
                        console.log('Error creating circuit', error);
                        setErrorMessage(error.message);
                    });
                break;
            default:
                setErrorMessage('Invalid circuit type');
                console.log('Invalid circuit type');
        }
    }

    return (
        <>
            <h2 style={{ marginTop: '5%', textAlign: 'center' }}>Create a new circuit</h2>
            <div className='row'>
                <div className='col-md-3' />
                <div className='col-md-6'>
                    <label>Circuit Name</label>
                    <input type="text" className="form-control" value={circuitName} onChange={(e) => setCircuitName(e.target.value)}/>
                    <br />
                    <label>Circuit Type</label>
                    <select className="form-select" onChange={(e) => setCircuitType(e.target.value)}>
                        <option value="">Select a circuit type</option>
                        <option value="recognition">Recognition</option>
                        <option value="retrieval">Retrieval</option>
                        <option value="sourceSeparation">Source Separation</option>
                    </select>
                    {renderForm()}
                    <br />
                    <button className="btn btn-primary text-center w-100" onClick={createCircuit}>Create Circuit</button>
                    <br />
                    <div id="errorMessage" className='text-danger' style={{ color: 'red' }}>{errorMessage}</div>
                </div>
            </div>
        </>
    );
}

export default Create;
