/*
 * File: Navbar.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Sunday, 28th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../auth/firebase';
const Navbar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        auth.signOut()
            .then(() => setUser(null))
            .catch((error) => console.error("Error signing out: ", error));
    };
    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-primary" >
            <div className="container-fluid" style={{ padding: '0px' }}>
                <img src="exoneuron-logo.svg" width="50" height="50"
                    className="d-inline-block align-text-top"
                    style={{ marginRight: "24px", marginLeft: "24px" }} 
                    alt=""
                    ></img>
                <Link className="navbar-brand" to='/home'>Exoneuron</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01"
                    aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarColor01">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" onClick={() => handleNavigation('/pricing')}>Pricing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={() => handleNavigation('/about')}>About us</a>
                        </li>
                        {user ? (
                            <>
                                <li className="nav-item">
                                    <a className="nav-link" onClick={() => handleNavigation('/Profile')}>Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link"
                                        onClick={handleLogout}
                                        style={{ backgroundColor: "darkorange", width: "100px", textAlign: "center", color: "black"}}
                                        href="#"
                                    >
                                        Logout
                                    </a>
                                </li>
                            </>
                        ) : (
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => handleNavigation('/login')}
                                    style={{ backgroundColor: "darkorange",  width: "100px", textAlign: "center", color: "black" }}
                                >Login</a>
                            </li>
                        )}
                    </ul>
                </div>
            </div >
        </nav >
    )
}
export default Navbar
