/*
 * File: Layout.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Friday, 26th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { auth } from "../auth/firebase";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = () => {
    const [user, setUser] = useState(null);

    // if not logged in, redirect to login page
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user !== null) {
                setUser(user);
            }
        });
        return unsubscribe;
    }, []);
    return (
        <>
            <Navbar />
            <div className="container-fluid" style={{ padding: '0px', margin: '0px' }}>
                <div className="row justify-content-center" style={{ marginTop: '0px', padding: '0px' }}>
                    <div className="col-sm-2" style={{ margin: '0px', padding: '0px' }}>
                        {user ? (
                            <div className="col-sm-2" style={{ margin: '0px', padding: '0px' }}>
                                <Sidebar user={user} />
                            </div>
                        ) : null}
                    </div>
                    <div className={`col-sm-${user ? '8' : '10'}`}>
                        <Outlet />
                    </div>
                    <div className={`col-sm-${user ? '2' : '0'}`}>
                        <></>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout