/*
 * File: Home.jsx
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Thursday, 4th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */




const Home = () => {
    return (
        <>
            <div className="d-flex flex-column align-items-center vh-100">
                <div className="container" style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
                    <h1 style={{ fontSize: "4rem" }}>
                        <img src="exoneuron-logo-dark.svg" width="360" height="360" className="d-inline-block align-text-top"
                            style={{ marginRight: "24px", marginLeft: "24px" }} alt="" />
                        <br />
                        Exoneuron
                    </h1>
                    <p style={{fontSize: "2rem"}}>Describe your own artificial brain, construct its environment, and tutor it as it masters its domain.</p>
                    <a className="link" href="about">Learn more</a>
                </div>
                <div className="container">
                    <hr style={{ margin: "50px" }} />
                </div>
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/property.png" style={{ margin: "10px" }} 
                                        alt=""
                                        />
                                        Full Ownership
                                    </h4>
                                    <p className="card-text">
                                        Your AI, of Your data on Your device. You have all the legal rights
                                        to your data and AI.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/mental-state.png" style={{ margin: "10px" }} 
                                        alt=""
                                        />
                                        Curious AI
                                    </h4>
                                    <p className="card-text">
                                        Your AI will ask you questions when it is unsure about something.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/code.png" style={{ margin: "10px" }} 
                                        alt=""
                                        />
                                        Multi-Platform
                                    </h4>
                                    <p className="card-text">
                                        We deploy your AI into your code through our multi-platform API.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/plug.png" style={{ margin: "10px" }} 
                                        alt=""
                                        />
                                        Plug and Play
                                    </h4>
                                    <p className="card-text">
                                        One service for all your needs. No more costs for data engineering,
                                        MLOps, GPU, or data clusters.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/circular-arrows--v1.png"
                                            style={{ margin: "10px" }} 
                                            alt=""
                                            />
                                        Continuous Learning
                                    </h4>
                                    <p className="card-text">
                                        Your AI interacts with the world through your code, learning from
                                        the observations in pseudo-realtime.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card text-white bg-dark mb-3" style={{ minHeight: "220px" }}>
                                <div className="card-body">
                                    <h4 className="card-title ">
                                        <img src="https://img.icons8.com/ios/50/FFFFFF/courthouse.png" style={{ margin: "10px" }} 
                                        alt=""
                                        />
                                        Legal Compliance
                                    </h4>
                                    <p className="card-text">
                                        Your AI will be compliant with GDPR, CCPA, and most other data
                                        privacy laws.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home