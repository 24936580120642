/*
 * File: firebase.js
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Wednesday, 10th April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD2VA2uiaS5ihCUVVha0ICp2WbdvPEhiFY",
  authDomain: "exoneuron-dev-412614.firebaseapp.com",
  projectId: "exoneuron-dev-412614",
  storageBucket: "exoneuron-dev-412614.appspot.com",
  messagingSenderId: "1047758868334",
  appId: "1:1047758868334:web:3b481a7927f0dae7b43b7f",
  measurementId: "G-J5S0GVCLXM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);


const list_circuits = httpsCallable(functions, 'list_circuits');
const create_circuit = httpsCallable(functions, 'create_circuit');
const circuit_usage = httpsCallable(functions, 'circuit_usage');

export { auth, circuit_usage, create_circuit, list_circuits };

