/*
 * File: index.js
 * File Created: Thursday, 28th March 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Sunday, 31st March 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import "bootswatch/dist/lux/bootstrap.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
