/*
 * File: CircuitDetail.jsx
 * File Created: Tuesday, 2nd April 2024
 * Author: Ali S. Razavian (ali@razavian.org)
 *  
 * Last Modified: Sunday, 21st April 2024
 * Modified By: Ali S. Razavian (ali@razavian.org)
 *  
 * Copyright 2020 - 2024 Exoneuron, Exoneuron AB
 */

import React from 'react';
import { useParams } from 'react-router-dom';
import { circuit_usage } from '../auth/firebase';

import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

function CircuitDetail() {
    let { circuitId } = useParams();
    const [circuitData, setCircuitData] = useState('');
    const [language, setLanguage] = useState('python');

    useEffect(() => {
        circuit_usage({ circuitId: circuitId, language: language })
            .then((result) => {
                setCircuitData(result.data);
            })
            .catch((error) => {
                console.error("Error fetching circuit usage: ", error);
            });
    }, [circuitId, language]);

    const handleSelect = (selectedLanguage) => {
        setLanguage(selectedLanguage);
    }
    const [copySuccess, setCopySuccess] = useState('');
    const onCopy = () => {
        setCopySuccess('Copied!');
    };
    useEffect(() => {
        if (copySuccess) {
            setTimeout(() => setCopySuccess(''), 2000);
        }
    }, [copySuccess]);

    return (
        <>
            <div className="alert alert-success" role="alert" style={{ opacity: copySuccess ? 1 : 0 }}>
                {copySuccess}
            </div>

            <div className='row'>
                <p></p>
            </div>
            <div className='row'>
                <div className='col-md-2'>
                </div>
                <div className='col-8'>
                    <h2> Circuit Usage </h2>
                    <Tabs defaultActiveKey="python" onSelect={handleSelect}>
                        <Tab eventKey="python" title="Python" />
                        <Tab eventKey="kotlin" title="Kotlin" />
                        <Tab eventKey="javascript" title="JavaScript" />
                        {/* Add more tabs as needed */}
                    </Tabs>
                    <div style={{ position: 'relative' }}>
                        <SyntaxHighlighter language={language} style={vscDarkPlus}>
                            {circuitData}
                        </SyntaxHighlighter>
                        <CopyToClipboard text={circuitData} onCopy={onCopy}>
                            <button style={{
                                position: 'absolute',
                                top: 20,
                                right: 20,
                                opacity: 0.5,
                                backgroundColor: 'transparent',
                                color: 'text-secondary',
                                border: 'none',
                                width: '30px',
                                height: '30px',
                                margin: 0,
                                padding: 0,
                            }}>
                                <img src="https://img.icons8.com/ios/30/FFFFFF/copy-2.png" alt="copy" />
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='col-2'>
                </div>
            </div>
        </>
    );
}

export default CircuitDetail;

